import _ from "lodash";
import { StatisticsFilterType } from '@/constants';

const {
  EntityType,
  EmployeeType
} = StatisticsFilterType;

export default {
  isOrdersValid({ order: { type } }) {
    if (_.isNil(type)) {
      return false;
    }

    return true;
  },
  isEmployeesValid({ employee: { id, type, order: { type: orderType } } }) {
    if (_.isNil(type) || _.isNil(orderType)) {
      return false;
    }

    if (type === EmployeeType.Individual && _.isNil(id)) {
      return false;
    }

    return true;
  },
  isCustomersValid({ customer: { id } }) {
    return !_.isNil(id);
  },
  isProductsValid({ product: { type }}) {
    if (_.isNil(type)) {
      return false;
    }

    return true;
  },
  validate({ entity, ...formData }) {
    if (_.isNil(entity)) {
      return false;
    }

    switch(entity) {
      case EntityType.Order:
        return this.isOrdersValid(formData);
      case EntityType.Employee:
        return this.isEmployeesValid(formData);
      case EntityType.Customer:
        return this.isCustomersValid(formData);
      case EntityType.Product:
        return this.isProductsValid(formData);
      default:
        return true;
    }
  },
};

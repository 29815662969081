<template>
  <div>
    <el-form :model="formData">
      <el-row>
        <el-col :span="24">
          <el-form-item>
            <el-radio-group v-model="formData.entity">
              <el-radio :label="EntityType.Order">Ordenes</el-radio>
              <el-radio :label="EntityType.Employee">Técnicos</el-radio>
              <el-radio :label="EntityType.Customer">Clientes</el-radio>
              <el-radio :label="EntityType.Product">Inventario</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>

      <div v-if="formData.entity === EntityType.Order">
        <el-row>
          <el-col :span="24">
            <el-form-item>
              <el-radio-group v-model="formData.order.type">
                <el-radio :label="OrderType.All">Todas</el-radio>
                <el-radio :label="OrderType.Service">Servicios</el-radio>
                <el-radio :label="OrderType.Product">Productos</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>

        <div v-if="formData.order.type" class="mx-6">
          <div class="border border-dashed pt-2">
            <el-row v-if="formData.order.type === OrderType.All" class="py-0 px-6">
              <el-col :span="12" class="pr-2">
                <el-form-item label="Categoria de dispositivo">
                  <el-autocomplete v-model="formData.order.device.category.name" :trigger-on-focus="false"
                  :fetch-suggestions="fetchDeviceCategories" class="w-full" popper-class="device-autocomplete"
                  @select="handleOrderDeviceCategorySelect" @change="handleOrderDeviceCategoryChange">
                  <template slot-scope="{ item }">
                    <div class="deviceCategory">{{ item.name }}</div>
                  </template>
                </el-autocomplete>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="Marca">
                  <el-autocomplete v-model="formData.order.device.brand" :trigger-on-focus="false"
                  :fetch-suggestions="fetchDeviceBrands" class="w-full" popper-class="device-autocomplete"
                  @select="handleOrderDeviceBrandSelect" @change="handleOrderDeviceBrandChange"
                  :disabled="!formData.order.device.category.id">
                  <template slot-scope="{ item }">
                    <div class="deviceBrand">{{ item }}</div>
                  </template>
                </el-autocomplete>
                </el-form-item>
              </el-col>

              <el-col :span="24">
                <el-form-item label="Dispositivo">
                  <el-autocomplete v-model="formData.order.device.name" :trigger-on-focus="false"
                    :fetch-suggestions="fetchDevices" class="w-full" popper-class="device-autocomplete"
                    @select="handleOrderDeviceSelect" :disabled="!formData.order.device.brand">
                    <template slot-scope="{ item }">
                      <div class="deviceName">{{ item.brand }} - {{ item.model }}</div>
                      <span class="deviceType">{{ item.deviceType.name }}</span>
                    </template>
                  </el-autocomplete>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row v-if="formData.order.type === OrderType.Service" class="py-0 px-6">
              <el-col :span="12" class="pr-2">
                <el-form-item label="Categoria de dispositivo">
                  <el-autocomplete v-model="formData.order.device.category.name" :trigger-on-focus="false"
                  :fetch-suggestions="fetchDeviceCategories" class="w-full" popper-class="device-autocomplete"
                  @select="handleOrderDeviceCategorySelect" @change="handleOrderDeviceCategoryChange">
                  <template slot-scope="{ item }">
                    <div class="deviceCategory">{{ item.name }}</div>
                  </template>
                </el-autocomplete>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="Marca">
                  <el-autocomplete v-model="formData.order.device.brand" :trigger-on-focus="false"
                  :fetch-suggestions="fetchDeviceBrands" class="w-full" popper-class="device-autocomplete"
                  @select="handleOrderDeviceBrandSelect" @change="handleOrderDeviceBrandChange"
                  :disabled="!formData.order.device.category.id">
                  <template slot-scope="{ item }">
                    <div class="deviceBrand">{{ item }}</div>
                  </template>
                </el-autocomplete>
                </el-form-item>
              </el-col>

              <el-col :span="24">
                <el-form-item label="Dispositivo">
                  <el-autocomplete v-model="formData.order.device.name" :trigger-on-focus="false"
                    :fetch-suggestions="fetchDevices" class="w-full" popper-class="device-autocomplete"
                    @select="handleOrderDeviceSelect" :disabled="!formData.order.device.brand">
                    <template slot-scope="{ item }">
                      <div class="deviceName">{{ item.brand }} - {{ item.model }}</div>
                      <span class="deviceType">{{ item.deviceType.name }}</span>
                    </template>
                  </el-autocomplete>
                </el-form-item>
              </el-col>

              <el-col :span="24" class="pr-2">
                <el-form-item label="Categoria de Servicio">
                  <el-select class="w-full" v-model="formData.order.service.category">
                    <el-option v-for="serviceCategory of $store.state.catalogs.order.service.categories"
                      :key="serviceCategory.id" :value="serviceCategory.id" :label="serviceCategory.name">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row v-if="formData.order.type === OrderType.Product" class="py-0 px-6">
              <el-col :span="12" class="pr-2">
                <el-form-item label="Categoria de dispositivo">
                  <el-autocomplete v-model="formData.order.device.category.name" :trigger-on-focus="false"
                  :fetch-suggestions="fetchDeviceCategories" class="w-full" popper-class="device-autocomplete"
                  @select="handleOrderDeviceCategorySelect" @change="handleOrderDeviceCategoryChange">
                  <template slot-scope="{ item }">
                    <div class="deviceCategory">{{ item.name }}</div>
                  </template>
                </el-autocomplete>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="Marca">
                  <el-autocomplete v-model="formData.order.device.brand" :trigger-on-focus="false"
                  :fetch-suggestions="fetchDeviceBrands" class="w-full" popper-class="device-autocomplete"
                  @select="handleOrderDeviceBrandSelect" @change="handleOrderDeviceBrandChange"
                  :disabled="!formData.order.device.category.id">
                  <template slot-scope="{ item }">
                    <div class="deviceBrand">{{ item }}</div>
                  </template>
                </el-autocomplete>
                </el-form-item>
              </el-col>

              <el-col :span="24">
                <el-form-item label="Dispositivo">
                  <el-autocomplete v-model="formData.order.device.name" :trigger-on-focus="false"
                    :fetch-suggestions="fetchDevices" class="w-full" popper-class="device-autocomplete"
                    @select="handleOrderDeviceSelect" :disabled="!formData.order.device.brand">
                    <template slot-scope="{ item }">
                      <div class="deviceName">{{ item.brand }} - {{ item.model }}</div>
                      <span class="deviceType">{{ item.deviceType.name }}</span>
                    </template>
                  </el-autocomplete>
                </el-form-item>
              </el-col>

              <el-col :span="12" class="pr-2">
                <el-form-item label="Categoria de Producto">
                  <el-select class="w-full" v-model="formData.order.product.category">
                    <el-option v-for="productCategory of $store.state.catalogs.product.categories"
                      :key="productCategory.id" :value="productCategory.id" :label="productCategory.name">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="Marca (Nombre de Producto)">
                  <el-input v-model="formData.order.product.model"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>

      <div v-if="formData.entity === EntityType.Employee">
        <el-row class="py-0 px-6">
          <el-col :span="24">
            <el-form-item label="Selecciona todos o un técnico en específico">
              <el-select class="w-full" v-model="formData.employee.id" @change="handleEmployeeSelection">
                <el-option :value="EmployeeType.All" label="Todos" />
                <el-option
                  v-for="employee of $store.state.business.employees.filter((employee) => employee.accountType === AccountTypeEnum.Technical)"
                  :key="employee.id" :value="employee.id" :label="employee.fullname">
                  {{ employee.fullname }}
                  <span class="text-sm ml-2 text-gray-500">{{ employee.email }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <div v-if="formData.employee.type">
          <el-row>
            <el-col :span="24">
              <el-form-item>
                <el-radio-group v-model="formData.employee.order.type">
                  <el-radio :label="OrderType.All">Todas</el-radio>
                  <el-radio :label="OrderType.Service">Servicios</el-radio>
                  <el-radio :label="OrderType.Product">Productos</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>

          <div v-if="formData.employee.order.type" class="mx-6">
            <div class="border border-dashed pt-2">
              <el-row v-if="formData.employee.order.type === OrderType.All" class="py-0 px-6">
                <el-col :span="12" class="pr-2">
                  <el-form-item label="Categoria de dispositivo">
                    <el-autocomplete v-model="formData.employee.order.device.category.name" :trigger-on-focus="false"
                    :fetch-suggestions="fetchDeviceCategories" class="w-full" popper-class="device-autocomplete"
                    @select="handleEmployeeOrderDeviceCategorySelect" @change="handleEmployeeOrderDeviceCategoryChange">
                    <template slot-scope="{ item }">
                      <div class="deviceCategory">{{ item.name }}</div>
                    </template>
                  </el-autocomplete>
                  </el-form-item>
                </el-col>

                <el-col :span="12">
                  <el-form-item label="Marca">
                    <el-autocomplete v-model="formData.employee.order.device.brand" :trigger-on-focus="false"
                    :fetch-suggestions="fetchDeviceBrands" class="w-full" popper-class="device-autocomplete"
                    @select="handleEmployeeOrderDeviceBrandSelect" @change="handleEmployeeOrderDeviceBrandChange"
                    :disabled="!formData.employee.order.device.category.id">
                    <template slot-scope="{ item }">
                      <div class="deviceBrand">{{ item }}</div>
                    </template>
                  </el-autocomplete>
                  </el-form-item>
                </el-col>

                <el-col :span="24">
                  <el-form-item label="Dispositivo">
                    <el-autocomplete v-model="formData.employee.order.device.name" :trigger-on-focus="false"
                      :fetch-suggestions="fetchDevices" class="w-full" popper-class="device-autocomplete"
                      @select="handleEmployeeOrderDeviceSelect" :disabled="!formData.employee.order.device.brand">
                      <template slot-scope="{ item }">
                        <div class="deviceName">{{ item.brand }} - {{ item.model }}</div>
                        <span class="deviceType">{{ item.deviceType.name }}</span>
                      </template>
                    </el-autocomplete>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row v-if="formData.employee.order.type === OrderType.Service" class="py-0 px-6">
                <el-col :span="12" class="pr-2">
                  <el-form-item label="Categoria de dispositivo">
                    <el-autocomplete v-model="formData.employee.order.device.category.name" :trigger-on-focus="false"
                    :fetch-suggestions="fetchDeviceCategories" class="w-full" popper-class="device-autocomplete"
                    @select="handleEmployeeOrderDeviceCategorySelect" @change="handleEmployeeOrderDeviceCategoryChange">
                    <template slot-scope="{ item }">
                      <div class="deviceCategory">{{ item.name }}</div>
                    </template>
                  </el-autocomplete>
                  </el-form-item>
                </el-col>

                <el-col :span="12">
                  <el-form-item label="Marca">
                    <el-autocomplete v-model="formData.employee.order.device.brand" :trigger-on-focus="false"
                    :fetch-suggestions="fetchDeviceBrands" class="w-full" popper-class="device-autocomplete"
                    @select="handleEmployeeOrderDeviceBrandSelect" @change="handleEmployeeOrderDeviceBrandChange"
                    :disabled="!formData.employee.order.device.category.id">
                    <template slot-scope="{ item }">
                      <div class="deviceBrand">{{ item }}</div>
                    </template>
                  </el-autocomplete>
                  </el-form-item>
                </el-col>

                <el-col :span="24">
                  <el-form-item label="Dispositivo">
                    <el-autocomplete v-model="formData.employee.order.device.name" :trigger-on-focus="false"
                      :fetch-suggestions="fetchDevices" class="w-full" popper-class="device-autocomplete"
                      @select="handleEmployeeOrderDeviceSelect" :disabled="!formData.employee.order.device.brand">
                      <template slot-scope="{ item }">
                        <div class="deviceName">{{ item.brand }} - {{ item.model }}</div>
                        <span class="deviceType">{{ item.deviceType.name }}</span>
                      </template>
                    </el-autocomplete>
                  </el-form-item>
                </el-col>

                <el-col :span="24" class="pr-2">
                  <el-form-item label="Categoria de Servicio">
                    <el-select class="w-full" v-model="formData.employee.order.service.category">
                      <el-option v-for="serviceCategory of $store.state.catalogs.order.service.categories"
                        :key="serviceCategory.id" :value="serviceCategory.id" :label="serviceCategory.name">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row v-if="formData.employee.order.type === OrderType.Product" class="py-0 px-6">
                <el-col :span="12" class="pr-2">
                  <el-form-item label="Categoria de dispositivo">
                    <el-autocomplete v-model="formData.employee.order.device.category.name" :trigger-on-focus="false"
                    :fetch-suggestions="fetchDeviceCategories" class="w-full" popper-class="device-autocomplete"
                    @select="handleEmployeeOrderDeviceCategorySelect" @change="handleEmployeeOrderDeviceCategoryChange">
                    <template slot-scope="{ item }">
                      <div class="deviceCategory">{{ item.name }}</div>
                    </template>
                  </el-autocomplete>
                  </el-form-item>
                </el-col>

                <el-col :span="12">
                  <el-form-item label="Marca">
                    <el-autocomplete v-model="formData.employee.order.device.brand" :trigger-on-focus="false"
                    :fetch-suggestions="fetchDeviceBrands" class="w-full" popper-class="device-autocomplete"
                    @select="handleEmployeeOrderDeviceBrandSelect" @change="handleEmployeeOrderDeviceBrandChange"
                    :disabled="!formData.employee.order.device.category.id">
                    <template slot-scope="{ item }">
                      <div class="deviceBrand">{{ item }}</div>
                    </template>
                  </el-autocomplete>
                  </el-form-item>
                </el-col>

                <el-col :span="24">
                  <el-form-item label="Dispositivo">
                    <el-autocomplete v-model="formData.employee.order.device.name" :trigger-on-focus="false"
                      :fetch-suggestions="fetchDevices" class="w-full" popper-class="device-autocomplete"
                      @select="handleEmployeeOrderDeviceSelect" :disabled="!formData.employee.order.device.brand">
                      <template slot-scope="{ item }">
                        <div class="deviceName">{{ item.brand }} - {{ item.model }}</div>
                        <span class="deviceType">{{ item.deviceType.name }}</span>
                      </template>
                    </el-autocomplete>
                  </el-form-item>
                </el-col>

                <el-col :span="12" class="pr-2">
                  <el-form-item label="Categoria de Producto">
                    <el-select class="w-full" v-model="formData.employee.order.product.category">
                      <el-option v-for="productCategory of $store.state.catalogs.product.categories"
                        :key="productCategory.id" :value="productCategory.id" :label="productCategory.name">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

                <el-col :span="12">
                  <el-form-item label="Marca (Nombre de Producto)">
                    <el-input v-model="formData.employee.order.product.model"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </div>

      <div v-if="formData.entity === EntityType.Customer">
        <div class="mx-6">
          <div class="border border-dashed pt-2">
            <el-row class="py-0 px-6">
              <el-col :span="24">
                <el-form-item label="Selecciona un cliente en específico">
                  <table class="w-full table-auto">
                    <thead>
                      <tr>
                        <th class="px-4 py-2">Nombre</th>
                        <th class="px-4 py-2">Teléfono</th>
                        <th class="px-4 py-2">Correo electrónico</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="customer in customers" :key="customer.id"
                        @click="formData.customer.id = customer.id;" class="text-left cursor-pointer">
                        <td class="border px-4 py-2">
                          <el-radio v-model="formData.customer.id" :label="customer.id">
                            &nbsp;{{ customer.fullName }}
                          </el-radio>
                        </td>
                        <td class="border px-4 py-2">{{ customer.phone }}</td>
                        <td class="border px-4 py-2">{{ customer.email }}</td>
                      </tr>
                    </tbody>
                  </table>

                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>

      <div v-if="formData.entity === EntityType.Product">
        <el-row>
          <el-col :span="24">
            <el-form-item>
              <el-radio-group v-model="formData.product.type">
                <el-radio :label="ProductType.Sold">Vendido</el-radio>
                <el-radio :label="ProductType.Stock">Stock</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>

        <div v-if="formData.product.type" class="mx-6">
          <div class="border border-dashed pt-2">
            <el-row v-if="formData.product.type === ProductType.Sold" class="py-0 px-6">
              <el-col :span="12" class="pr-2">
                <el-form-item label="Categoria de dispositivo">
                  <el-autocomplete v-model="formData.product.device.category.name" :trigger-on-focus="false"
                  :fetch-suggestions="fetchDeviceCategories" class="w-full" popper-class="device-autocomplete"
                  @select="handleProductDeviceCategorySelect" @change="handleProductDeviceCategoryChange">
                  <template slot-scope="{ item }">
                    <div class="deviceCategory">{{ item.name }}</div>
                  </template>
                </el-autocomplete>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="Marca">
                  <el-autocomplete v-model="formData.product.device.brand" :trigger-on-focus="false"
                  :fetch-suggestions="fetchDeviceBrands" class="w-full" popper-class="device-autocomplete"
                  @select="handleProductDeviceBrandSelect" @change="handleProductDeviceBrandChange"
                  :disabled="!formData.product.device.category.id">
                  <template slot-scope="{ item }">
                    <div class="deviceBrand">{{ item }}</div>
                  </template>
                </el-autocomplete>
                </el-form-item>
              </el-col>

              <el-col :span="24">
                <el-form-item label="Dispositivo">
                  <el-autocomplete v-model="formData.product.device.name" :trigger-on-focus="false"
                    :fetch-suggestions="fetchDevices" class="w-full" popper-class="device-autocomplete"
                    @select="handleProductDeviceSelect" :disabled="!formData.product.device.brand">
                    <template slot-scope="{ item }">
                      <div class="deviceName">{{ item.brand }} - {{ item.model }}</div>
                      <span class="deviceType">{{ item.deviceType.name }}</span>
                    </template>
                  </el-autocomplete>
                </el-form-item>
              </el-col>

              <el-col :span="12" class="pr-2">
                <el-form-item label="Categoria de Producto">
                  <el-select class="w-full" v-model="formData.product.sold.category">
                    <el-option v-for="productCategory of $store.state.catalogs.product.categories"
                      :key="productCategory.id" :value="productCategory.id" :label="productCategory.name">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="Marca (Nombre de Producto)">
                  <el-input v-model="formData.product.sold.model"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row v-if="formData.product.type === ProductType.Stock" class="py-0 px-6">
              <el-col :span="12" class="pr-2">
                <el-form-item label="Categoria de dispositivo">
                  <el-autocomplete v-model="formData.product.device.category.name" :trigger-on-focus="false"
                  :fetch-suggestions="fetchDeviceCategories" class="w-full" popper-class="device-autocomplete"
                  @select="handleProductDeviceCategorySelect" @change="handleProductDeviceCategoryChange">
                  <template slot-scope="{ item }">
                    <div class="deviceCategory">{{ item.name }}</div>
                  </template>
                </el-autocomplete>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="Marca">
                  <el-autocomplete v-model="formData.product.device.brand" :trigger-on-focus="false"
                  :fetch-suggestions="fetchDeviceBrands" class="w-full" popper-class="device-autocomplete"
                  @select="handleProductDeviceBrandSelect" @change="handleProductDeviceBrandChange"
                  :disabled="!formData.product.device.category.id">
                  <template slot-scope="{ item }">
                    <div class="deviceBrand">{{ item }}</div>
                  </template>
                </el-autocomplete>
                </el-form-item>
              </el-col>

              <el-col :span="24">
                <el-form-item label="Dispositivo">
                  <el-autocomplete v-model="formData.product.device.name" :trigger-on-focus="false"
                    :fetch-suggestions="fetchDevices" class="w-full" popper-class="device-autocomplete"
                    @select="handleProductDeviceSelect" :disabled="!formData.product.device.brand">
                    <template slot-scope="{ item }">
                      <div class="deviceName">{{ item.brand }} - {{ item.model }}</div>
                      <span class="deviceType">{{ item.deviceType.name }}</span>
                    </template>
                  </el-autocomplete>
                </el-form-item>
              </el-col>

              <el-col :span="12" class="pr-2">
                <el-form-item label="Categoria de Producto">
                  <el-select class="w-full" v-model="formData.product.stock.category">
                    <el-option v-for="productCategory of $store.state.catalogs.product.categories"
                      :key="productCategory.id" :value="productCategory.id" :label="productCategory.name">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="Marca (Nombre de Producto)">
                  <el-input v-model="formData.product.stock.model"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </el-form>

    <div class="mt-8">
      <el-button class="mr-2" @click="clear()" type="info">
        <i class="bx bx-trash"></i>&nbsp;Limpiar
      </el-button>
      <el-button @click="fetch()" type="primary" v-if="isProMembership">
        <i class="bx bx-search"></i>&nbsp;Buscar
      </el-button>
      <el-button type="info" v-if="!isProMembership" class="cursor-not-allowed" disabled>
        <i class="bx bx-search"></i>&nbsp;Buscar
      </el-button>
    </div>

  </div>
</template>
<style>
.device {
  width: max-content;
  left: 42%;
  top: -10px;
}

.device-autocomplete {
  li {
    line-height: normal !important;
    padding: 7px !important;

    .deviceCategory {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .deviceBrand {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .deviceName {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .deviceTypes {
      font-size: 12px;
      color: #b4b4b4;
    }
  }
}
</style>
<script>
import _ from "lodash";
import { AccountTypeEnum, StatisticsFilterType } from '@/constants';
import builder from './builder';
import StatisticService from '@/services/StatisticService';
import CatalogService from '@/services/CatalogService';
import OrderService from '@/services/OrderService';

const init = {
  entity: null,
  order: {
    type: null,
    device: { id: null, name: '', brand: '', category: { id: null, name: '' } },
    service: { category: null },
    product: { category: null, model: '' },
  },
  employee: {
    id: null,
    type: null,
    order: {
      type: null,
      device: { id: null, name: '', brand: '', category: { id: null, name: '' } },
      service: { category: null },
      product: { category: null, model: '' },
    },
  },
  customer: {
    id: null,
  },
  product: {
    type: null,
    device: { id: null, name: '', brand: '', category: { id: null, name: '' } },
    sold: { category: null, model: '' },
    stock: { category: null, model: '' },
  },
};

export default {
  name: 'StatisticsFilters',
  mixins: [builder],
  mounted() {
    this.getTop10Customers();
  },
  data() {
    return {
      isProMembership: this.$store.state.account.membershipType === 2 || this.$store.state.account.membershipType === 3,
      ...StatisticsFilterType,
      AccountTypeEnum,
      customers: [],
      formData: _.cloneDeep(init),
    };
  },
  methods: {
    handleEmployeeSelection(selection) {
      if (selection === this.EmployeeType.All) {
        this.formData.employee.type = this.EmployeeType.All;
        this.formData.employee.id = this.EmployeeType.All;
      } else {
        this.formData.employee.type = this.EmployeeType.Individual;
        this.formData.employee.id = selection;
      }
    },
    handleOrderDeviceCategorySelect(category) {
      if (category) {
        this.formData.order.device.category.id = category.id;
        this.formData.order.device.category.name = category.name;
      } else {
        this.formData.order.device.category.id = null;
        this.formData.order.device.category.name = "";
      }
    },
    handleEmployeeOrderDeviceCategorySelect(category) {
      if (category) {
        this.formData.employee.order.device.category.id = category.id;
        this.formData.employee.order.device.category.name = category.name;
      } else {
        this.formData.employee.order.device.category.id = null;
        this.formData.employee.order.device.category.name = "";
      }
    },
    handleOrderDeviceCategoryChange(value) {
      if (!value) {
        this.handleOrderDeviceCategorySelect();
        this.handleOrderDeviceBrandSelect("");
        this.handleOrderDeviceSelect();
      }
    },
    handleEmployeeOrderDeviceCategoryChange(value) {
      if (!value) {
        this.handleEmployeeOrderDeviceCategorySelect();
        this.handleEmployeeOrderDeviceBrandSelect("");
        this.handleOrderDeviceSelect();
      }
    },
    handleOrderDeviceBrandSelect(brand) {
      this.formData.order.device.brand = brand;
    },
    handleEmployeeOrderDeviceBrandSelect(brand) {
      this.formData.employee.order.device.brand = brand;
    },
    handleOrderDeviceBrandChange(value) {
      if (!value) {
        this.handleOrderDeviceBrandSelect("");
        this.handleOrderDeviceSelect();
      }
    },
    handleEmployeeOrderDeviceBrandChange(value) {
      if (!value) {
        this.handleEmployeeOrderDeviceBrandSelect("");
        this.handleEmployeeOrderDeviceSelect();
      }
    },
    handleOrderDeviceSelect(device) {
      if (device) {
        this.formData.order.device.id = device.id;
        this.formData.order.device.name = `${device.brand} - ${device.model}`;
      } else {
        this.formData.order.device.id = null;
        this.formData.order.device.name = "";
      }
    },
    handleEmployeeOrderDeviceSelect(device) {
      if (device) {
        this.formData.employee.order.device.id = device.id;
        this.formData.employee.order.device.name = `${device.brand} - ${device.model}`;
      } else {
        this.formData.employee.order.device.id = null;
        this.formData.employee.order.device.name = "";
      }
    },
    handleEmployeeDeviceSelect(device) {
      this.formData.employee.order.device.id = device.id;
      this.formData.employee.order.device.name = `${device.brand} ${device.model}`;
    },
    handleProductDeviceCategorySelect(category) {
      if (category) {
        this.formData.product.device.category.id = category.id;
        this.formData.product.device.category.name = category.name;
      } else {
        this.formData.product.device.category.id = null;
        this.formData.product.device.category.name = "";
      }
    },
    handleProductDeviceCategoryChange(value) {
      if (!value) {
        this.handleProductDeviceCategorySelect();
        this.handleProductDeviceBrandSelect();
        this.handleProductDeviceSelect();
      }
    },
    handleProductDeviceBrandSelect(brand) {
      this.formData.product.device.brand = brand;
    },
    handleProductDeviceBrandChange(value) {
      if (!value) {
        this.handleProductDeviceBrandSelect("");
        this.handleProductDeviceSelect();
      }
    },
    handleProductDeviceSelect(device) {
      if (device) {
        this.formData.product.device.id = device.id;
        this.formData.product.device.name = `${device.brand} - ${device.model}`;
      } else {
        this.formData.product.device.id = null;
        this.formData.product.device.name = "";
      }
    },
    async fetchDeviceCategories(queryString, callback) {
      try {
        const categories = await OrderService.getDeviceTypes({});
        const filteredCategories = categories.filter((category) =>
          category.name.toLowerCase().includes(queryString.toLowerCase()),
        );
        callback(filteredCategories);
      } catch (error) {
        console.log(error);
      }
    },
    async fetchDeviceBrands(queryString, callback) {
      try {
        const { data: devices } = await CatalogService.getAllDeviceModels({
          textSearch: queryString,
          pageSize: 100,
          pageNumber: 1,
          deviceTypeId: this.formData.order.device.category.id,
        });
        const brands = devices.map((device) => device.brand);
        const uniqueBrands = new Set();
        const filteredBrands = brands.filter((brand) => {
          const lowerCaseBrand = brand.toLowerCase();
          if (lowerCaseBrand.includes(queryString.toLowerCase()) && !uniqueBrands.has(lowerCaseBrand)) {
            uniqueBrands.add(lowerCaseBrand);
            return true;
          }
          return false;
        });
        callback(filteredBrands);
      } catch (error) {
        console.log(error);
      }
    },
    async fetchDevices(queryString, callback) {
      try {
        const { data } = await CatalogService.getAllDeviceModels({
          textSearch: queryString,
          pageSize: 100,
          pageNumber: 1,
          deviceTypeId: this.formData.order.device.category.id,
        });
        const devices = data.filter((device) =>
          device.brand.toLowerCase().includes(this.formData.order.device.brand.toLowerCase()),
        );
        callback(devices);
      } catch (error) {
        console.log(error);
      }
    },
    clear() {
      this.formData = { ...init };
    },
    fetch() {
      try {
        const data = this.build(this.formData).validate().get();
        this.$emit('on:fetch', data);
      } catch (error) {
        this.$toastr.e(error.message);
      }
    },
    async getTop10Customers() {
      this.customers = await StatisticService.getTop10Customers();
    },
  }
}
</script>

import _ from "lodash";
import { StatisticsFilterType } from '@/constants';
import validations from './validations';

const {
  EntityType,
  OrderType,
  EmployeeType,
  ProductType,
} = StatisticsFilterType;

const buildOrderData = ({ type, device, service, product }) => {
  const data = {
    type: type,
    deviceId: undefined,
    service: undefined,
    product: undefined,
    deviceCategoryId: undefined,
    deviceBrand: undefined,
  };

  if (type === OrderType.Service) {
    data.service = service;
  }

  if (type === OrderType.Product) {
    data.product = product;
  }

  if (device?.name) {
    data.deviceId = device.id;
  } else if (device?.brand) {
    data.deviceBrand = device.brand;
  } else if (device?.category?.name) {
    data.deviceCategoryId = device.category.id;
  }

  return { order: data };
};

const buildEmployeeData = ({ type, id, order: { type: OrderType, device, service, product }}) => {
  const { order } = buildOrderData({ type: OrderType, device, service, product });

  const data = {
    employee: {
      employeeId: undefined,
      type: type,
      order: order,
    },
  };

  if (type === EmployeeType.Individual) {
    data.employee.employeeId = id;
  }

  return data;
};

const buildCustomerData = ({ id }) => ({
  customer: {
    customerId: id,
  },
});

const buildProductData = ({ type, device, sold, stock }) => {
  const data = {
    type: type,
    deviceId: undefined,
    sold: undefined,
    stock: undefined,
    deviceCategoryId: undefined,
    deviceBrand: undefined,
  };

  if (type === ProductType.Sold) {
    data.sold = sold;
  }

  if (type === ProductType.Stock) {
    data.stock = stock;
  }

  if (!_.isNil(device?.name)) {
    data.deviceId = device.id;
  } else if (!_.isNil(device?.brand)) {
    data.deviceBrand = device.brand;
  } else if (!_.isNil(device?.category?.name)) {
    data.deviceCategoryId = device.category.id;
  }

  return { product: data };
};

export default {
  methods: {
    build(formData) {
      const builder = {
        formData,
        validate() {
          const isValid = validations.validate(formData);
          if (!isValid) {
            throw new Error("Error de Validación");
          }
          return this;
        },
        get() {
          let data = {};
          if (formData.entity === EntityType.Order) {
            data = buildOrderData(formData.order);
          } else if (formData.entity === EntityType.Employee) {
            data = buildEmployeeData(formData.employee);
          } else if (formData.entity === EntityType.Customer) {
            data = buildCustomerData(formData.customer);
          } else if (formData.entity === EntityType.Product) {
            data = buildProductData(formData.product);
          }

          return { ...data, entity: formData.entity };
        },
      };

      return builder;
    },
  },
};
